/**
 * NextJS custom app module.
 *
 * Ref: https://nextjs.org/docs/advanced-features/custom-app
 *
 * Next.js uses the App component to initialize pages. You can override it and
 * control the page initialization and:
 *   - Persist layouts between page changes
 *   - Keeping state when navigating pages
 *   - Inject additional data into pages
 *   - Add global CSS
 *
 * The most important aspect to remember about this module is that it executes
 * in-browser. This gives the application a vector through wich in-browser
 * operations can be performed safely -- that is, without checking that the
 * component is mounted before performing the operation.
 */
import '../styles/globals.css';
import 'focus-visible';

import { FC, useEffect, useRef } from 'react';
import type { AppProps } from 'next/app';
import Layout from 'components/templates/Layout';
import BrowserProvider from '../components/templates/BrowserProvider';

function usePrevious(value: string) {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const App: FC<AppProps> = ({ Component, pageProps, router }) => {
  const previousPathname = usePrevious(router.pathname);

  const Elem: any = Component;
  return (
    <Layout>
      <BrowserProvider>
        <Elem previousPathname={previousPathname} {...pageProps} />
      </BrowserProvider>
    </Layout>
  );
};

export default App;
