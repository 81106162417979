import * as React from 'react';
import { SWRConfig } from 'swr';

export const BrowserProvider: React.FC<{ children?: JSX.Element }> = ({
  children,
}) => {
  return (
    <SWRConfig
      value={{
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      {children}
    </SWRConfig>
  );
};
