import { FC, HTMLAttributes } from 'react';
import Link from 'next/link';
import { LinkProps } from 'next/link';
import classNames from '@Lib/classNames';

export interface LinkButtonProps
  extends LinkProps,
    HTMLAttributes<HTMLAnchorElement> {
  color: 'pink' | 'cyan';
}

export const LinkButton: FC<LinkButtonProps> = (props) => {
  const { className, color, ...remaining } = props;
  const baseClassNames = [
    'inline-grid',
    'w-full',
    'items-center',
    'justify-center',
    'rounded-md',
    'px-5',
    'py-3',
    'text-base',
    'text-white',
    'decoration-transparent',
  ];

  const nextClassName = classNames(
    baseClassNames,
    {
      'bg-pink-500': color === 'pink',
      'hover:bg-pink-600': color === 'pink',
      'bg-cyan-700': color === 'cyan',
      'hover:bg-cyan-500': color === 'cyan',
    },
    className
  );
  return (
    <div className="not-prose">
      <Link {...remaining} className={nextClassName} />
    </div>
  );
};
