import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import LinkButton from 'components/atoms/LinkButton';
import { Logo } from 'components/organisms/Logo/Logo';
import { Drawer } from 'components/templates/Drawer/Drawer';

const HeaderNavigationSection = () => {
  const [open, setOpen] = useState(false);
  return (
    <header className="not-prose relative z-10 h-16">
      <div className="fixed inset-x-0 top-0 h-16">
        <div className="border-b-2 border-b-pink-200 bg-white">
          <div className="container">
            <div className="flex flex-row justify-between px-4">
              <Link
                href="/"
                target="_self"
                className="inline-flex align-middle"
                title="Crown Town Cakes - Home"
              >
                <Logo className="m-auto h-10" />
              </Link>

              <div className="hidden md:block">
                <nav className="flex items-center gap-4">
                  <Link
                    href={{ pathname: '/pages/menu' }}
                    className="border-b-4 border-transparent p-3 uppercase decoration-transparent hover:border-cyan-500"
                  >
                    Menu
                  </Link>
                  <Link
                    href={{ pathname: '/gallery' }}
                    className="border-b-4 border-transparent p-3 uppercase decoration-transparent hover:border-cyan-500"
                  >
                    Gallery
                  </Link>
                  <Popover.Group className="md:gap-x-12">
                    <Popover>
                      <Popover.Button className="flex items-center border-b-4 border-transparent p-3 uppercase decoration-transparent hover:border-cyan-500">
                        More
                        <ChevronDownIcon
                          className="ml-1 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-cyan-50 pt-14 shadow-lg">
                          <div className="container mx-auto grid grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                            <div className="group relative flex flex-col rounded-xl border-2 border-yellow-100 bg-yellow-50 p-6 hover:bg-yellow-100">
                              <Link
                                href={{ pathname: '/pages/about-our-cakes' }}
                                className="mb-2 border-b-4 border-yellow-200 font-semibold tracking-tight hover:border-yellow-500"
                              >
                                About Our Cakes
                              </Link>
                              <label>
                                Learn More about how we make our cakes.
                              </label>
                            </div>
                            <div className="group relative flex flex-col rounded-xl border-2 border-yellow-100 bg-yellow-50 p-6 hover:bg-yellow-100">
                              <Link
                                href={{ pathname: '/pages/service-guide' }}
                                className="mb-2 border-b-4 border-yellow-200 font-semibold tracking-tight hover:border-yellow-500"
                              >
                                Service Guide
                              </Link>
                              <label>
                                Need advice on how to cut and serve your cake?
                                We have help!
                              </label>
                            </div>
                            <div className="group relative flex flex-col rounded-xl border-2 border-yellow-100 bg-yellow-50 p-6 hover:bg-yellow-100">
                              <Link
                                href={{ pathname: '/pages/how-it-works' }}
                                className="mb-2 border-b-4 border-yellow-200 font-semibold tracking-tight hover:border-yellow-500"
                              >
                                How it Works
                              </Link>
                              <label>
                                Our consultation process helps you design your
                                perfect cake.
                              </label>
                            </div>
                            <div className="group relative rounded-xl border-2 border-yellow-100 bg-yellow-50 p-6">
                              <label>More</label>
                              <ul>
                                <li>
                                  <Link
                                    href={{ pathname: '/pages/about-us' }}
                                    className="mb-2 border-b-4 border-yellow-200 tracking-tight hover:border-yellow-500"
                                  >
                                    About Us
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    href={{
                                      pathname: '/pages/terms-and-conditions',
                                    }}
                                    className="mb-2 border-b-4 border-yellow-200 tracking-tight hover:border-yellow-500"
                                  >
                                    Terms and Conditions
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    href={{
                                      pathname: '/pages/location',
                                    }}
                                    className="mb-2 border-b-4 border-yellow-200 tracking-tight hover:border-yellow-500"
                                  >
                                    Location
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  </Popover.Group>
                  <LinkButton
                    href={{ pathname: '/order' }}
                    color="cyan"
                    className="text-sm"
                  >
                    ORDER YOUR CAKE
                  </LinkButton>
                </nav>
              </div>

              {/* default navigation */}
              <div className="block md:hidden">
                <button
                  onClick={() => setOpen(true)}
                  className="my-2 inline-flex align-middle hover:bg-cyan-100"
                >
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon
                    className="h-10 text-cyan-500"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <Drawer open={open} setOpen={setOpen}>
                <div className="grid min-h-screen grid-rows-[min-content_1fr_min-content]">
                  <div className="flex h-16 items-center justify-between border-b-2 border-pink-100 px-4">
                    <Link
                      href="/"
                      target="_self"
                      className="inline-flex align-middle"
                      title="Crown Town Cakes - Home"
                    >
                      <Logo className="h-10" />
                    </Link>
                    <button
                      title="Close menu"
                      type="button"
                      className="inline-flex align-middle"
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon className="h-10 text-cyan-500" />
                    </button>
                  </div>

                  <nav className="not-prose flex flex-col px-4 py-6">
                    <Link
                      href={{ pathname: '/pages/menu' }}
                      className="cursor-pointer py-2 hover:underline hover:decoration-cyan-500 hover:decoration-4 hover:underline-offset-8 active:decoration-solid"
                    >
                      Menu
                    </Link>
                    <Link
                      href={{ pathname: '/gallery' }}
                      className="cursor-pointer py-2 hover:underline hover:decoration-cyan-500 hover:decoration-4 hover:underline-offset-8 active:decoration-solid"
                    >
                      Gallery
                    </Link>
                  </nav>
                  <div className="p-4">
                    <LinkButton
                      href={{ pathname: '/order' }}
                      color="cyan"
                      className=""
                    >
                      ORDER YOUR CAKE
                    </LinkButton>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNavigationSection;
