import { FC } from 'react';
import Link from 'next/link';

const links = [
  { id: '01', text: 'Home', href: '/' },
  { id: '02', text: 'Menu', href: '/pages/menu' },
  { id: '03', text: 'About Us', href: '/pages/about-us' },
  { id: '04', text: 'Order Your Cake', href: '/order' },
  {
    id: '05',
    text: 'Terms and Conditions',
    href: '/pages/terms-and-conditions',
  },
  { id: '06', text: 'Cake Gallery', href: '/gallery' },
];

const LayoutFooterSection: FC = () => {
  return (
    <footer
      className="bg-cyan-700/90 text-white"
      aria-labelledby="footer-heading"
    >
      <h2 className="sr-only">Footer</h2>
      <div className="container px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <nav className="not-prose grid grid-flow-row grid-rows-1 justify-items-center gap-4 md:grid-flow-col">
          {links.map((item) => (
            <Link
              key={item.id}
              href={item.href}
              className="border-b-4 border-transparent decoration-transparent hover:border-pink-500"
            >
              {item.text}
            </Link>
          ))}
        </nav>
        <hr />
        <p>Copyright © 2023 Crown Town Cakes. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default LayoutFooterSection;
