import { FC, ReactNode } from 'react';
import HeaderNavigationSection from 'components/organisms/HeaderNavigationSection';
import LayoutFooterSection from 'components/organisms/LayoutFooterSection';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <>
      <HeaderNavigationSection />
      {children}
      <LayoutFooterSection />
    </>
  );
};
