import { FC, HTMLAttributes } from 'react';

export type LogoProps = HTMLAttributes<SVGElement>;

export const Logo: FC<LogoProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" {...props}>
      <path
        fill="#06b6d4"
        d="M21.59,106c2.73,0.51,3.51,1.41,4.01,5.32c0.13,0.92,0.28,2.49,0.4,4.49
         c0.12,2,0.21,4.43,0.21,7.06c0,1.16-0.08,1.54-0.64,1.59c-1.62,0.14-3.76,0.12-4.79,0.12c-0.75,0-3.88-0.02-4.72-0.12
         c-0.56-0.07-0.64-0.25-0.68-0.98c-0.04-0.73-0.04-4.9-0.11-5.87c-0.04-0.61-0.16-0.85-0.57-0.98c-0.18-0.05-0.61-0.12-0.89-0.12
         c-0.29,0-0.72,0.06-0.89,0.12c-0.36,0.12-0.54,0.31-0.57,0.98c-0.06,1.22-0.08,2.72-0.09,4.36c-0.01,1.64-0.01,3.43-0.01,5.24
         c0,1.62,0,3.41,0.01,5.1c0.01,1.69,0.04,3.28,0.09,4.5c0.03,0.67,0.22,0.86,0.57,0.98c0.18,0.06,0.61,0.12,0.89,0.12
         c0.29,0,0.72-0.07,0.89-0.12c0.41-0.13,0.53-0.37,0.57-0.98c0.06-0.97,0.11-5.81,0.14-6.54c0.04-0.73,0.12-0.91,0.68-0.98
         c0.84-0.1,3.93-0.12,4.68-0.12c1.04,0,3.17-0.02,4.79,0.12c0.56,0.05,0.69,0.44,0.68,1.59c-0.02,2.61-0.13,5.23-0.26,7.4
         c-0.12,2.17-0.26,3.91-0.39,4.83c-0.5,3.91-1.27,4.81-4.01,5.32c-1.88,0.35-5.61,0.61-7.8,0.61c-2.33,0-5.76-0.25-7.8-0.61
         c-2.75-0.49-3.47-1.35-4.01-5.32c-0.2-1.47-0.38-3.65-0.52-6.36c-0.14-2.71-0.23-5.95-0.23-9.53c0-3.58,0.09-6.82,0.23-9.53
         c0.14-2.71,0.33-4.9,0.52-6.36C2.54,107.17,3.4,106.5,6,106c1.89-0.36,5.61-0.61,7.8-0.61C15.98,105.39,19.71,105.65,21.59,106z
         "
      />
      <path
        fill="#06b6d4"
        d="M57.49,146.59c0,1.1-0.32,1.71-1.04,1.83c-0.72,0.12-2.72,0.24-4.36,0.24
         c-1.65,0-3.26-0.07-3.97-0.18c-1.17-0.19-1.36-0.49-1.57-2.87c-0.13-1.42-0.19-2.13-0.32-3.55c-1.52,0-2.28,0-3.79,0
         c-0.13,1.42-0.19,2.13-0.32,3.55c-0.21,2.38-0.4,2.68-1.57,2.87c-0.71,0.12-2.32,0.18-3.97,0.18c-1.65,0-3.65-0.12-4.36-0.24
         c-0.72-0.12-1.04-0.74-1.04-1.83c0-0.98,0.18-2.51,0.39-4.03c0.38-2.77,0.72-5.27,1.03-7.6c0.32-2.33,0.61-4.49,0.9-6.6
         c0.29-2.11,0.57-4.16,0.85-6.27c0.29-2.11,0.58-4.27,0.9-6.6c0.32-2.33,0.66-4.82,1.04-7.6c0.18-1.41,0.65-1.89,1.5-1.96
         c0.86-0.06,4.4-0.18,6.55-0.18c2.15,0,5.69,0.12,6.55,0.18c0.86,0.06,1.32,0.55,1.5,1.96c0.38,2.77,0.72,5.27,1.04,7.6
         c0.32,2.33,0.61,4.49,0.9,6.6s0.57,4.16,0.85,6.27c0.29,2.11,0.58,4.27,0.9,6.6c0.32,2.33,0.66,4.82,1.04,7.6
         C57.31,144.08,57.49,145.61,57.49,146.59z M43.04,131.98c1.03,0,1.54,0,2.57,0c-0.24-2.81-0.43-4.92-0.61-7.03
         s-0.36-4.22-0.61-7.03c-0.06,0-0.08,0-0.14,0c-0.24,2.81-0.43,4.92-0.61,7.03S43.29,129.17,43.04,131.98z"
      />
      <path
        fill="#06b6d4"
        d="M88.92,146.95c0,0.37-0.03,0.67-0.18,0.92c-0.18,0.3-0.47,0.49-1,0.55
         c-1,0.12-2.47,0.18-3.86,0.18c-1.39,0-3.3,0.01-4.4-0.18c-1.35-0.23-1.79-0.74-2.25-3c-0.63-3.07-1.1-5.37-1.57-7.67
         c-0.47-2.3-0.94-4.6-1.57-7.67c0,3.22,0,5.63,0,8.04c0,2.41,0,4.82,0,8.04c0,1.53-0.33,2.08-1.07,2.2
         c-0.71,0.12-2.5,0.25-4.33,0.25c-1.79,0-3.58-0.13-4.29-0.25c-0.75-0.13-1.11-0.67-1.14-2.2c-0.04-1.65-0.06-3.5-0.08-5.49
         c-0.02-2-0.02-4.15-0.03-6.41c0-2.26,0-4.62,0-7.04c0-2.38,0-4.74,0-6.99c0-2.26,0.01-4.42,0.03-6.43
         c0.02-2.01,0.04-3.87,0.08-5.52c0.03-1.53,0.4-2.08,1.14-2.2c0.71-0.12,2.5-0.24,4.29-0.24c1.82,0,3.61,0.12,4.33,0.24
         c0.75,0.13,1.07,0.67,1.07,2.2c0,3.21,0,5.63,0,8.04c0,2.41,0,4.82,0,8.04c0.63-3.07,1.1-5.37,1.57-7.67
         c0.47-2.3,0.94-4.6,1.57-7.67c0.47-2.26,0.9-2.76,2.25-2.99c1.1-0.19,3.01-0.18,4.4-0.18c1.39,0,2.87,0.07,3.86,0.18
         c0.53,0.06,0.82,0.25,1,0.55c0.14,0.25,0.18,0.55,0.18,0.91c0,0.49-0.07,1.1-0.39,2.45c-0.56,2.31-1.02,4.23-1.46,6.02
         c-0.43,1.79-0.84,3.46-1.27,5.25c-0.43,1.79-0.9,3.72-1.46,6.02c0.56,2.31,1.02,4.23,1.46,6.02c0.43,1.79,0.84,3.46,1.27,5.25
         c0.43,1.79,0.9,3.72,1.46,6.02C88.85,145.85,88.92,146.47,88.92,146.95z"
      />
      <path
        fill="#06b6d4"
        d="M117.35,138.4c0.03,1.22,0.04,2.44,0.04,3.79c0,1.35,0,2.76-0.04,3.97
         c-0.04,1.52-0.5,2.08-1.25,2.2c-0.53,0.09-2.53,0.15-4.63,0.19c-2.1,0.04-4.3,0.05-5.24,0.05c-0.98,0-3.06,0-5.1-0.03
         c-2.04-0.03-4.05-0.09-4.88-0.21c-0.75-0.12-1.11-0.67-1.14-2.2c-0.04-1.65-0.06-3.51-0.08-5.52c-0.02-2.01-0.02-4.18-0.03-6.43
         c0-2.26,0-4.61,0-6.99c0-2.38,0-4.74,0-6.99c0-2.26,0.01-4.42,0.03-6.43c0.02-2.01,0.04-3.87,0.08-5.52
         c0.03-1.53,0.4-2.09,1.14-2.2c0.83-0.13,2.84-0.18,4.88-0.21c2.04-0.03,4.11-0.03,5.1-0.03c0.95,0,3.15,0.01,5.24,0.05
         s4.09,0.1,4.63,0.19c0.75,0.12,1.21,0.68,1.25,2.2c0.03,1.22,0.04,2.63,0.04,3.97c0,1.35,0,2.57-0.04,3.79
         c-0.04,1.28-0.25,1.71-0.89,1.71c-2.08,0-3.64,0-5.2,0c-1.56,0-3.12,0-5.2,0c-0.32,0-0.43,0.25-0.43,1.1c0,0.93,0,1.39,0,2.32
         c0,0.86,0.11,0.98,0.43,0.98c1.94,0,3.39,0,4.85,0s2.91,0,4.85,0c0.61,0,0.86,0.19,0.89,1.22c0.04,1.22,0.03,2.63,0.03,3.85
         c0,1.22,0,2.63-0.03,3.85c-0.03,1.04-0.29,1.22-0.89,1.22c-1.94,0-3.39,0-4.85,0s-2.91,0-4.85,0c-0.32,0-0.43,0.25-0.43,1.1
         c0,0.93,0,1.39,0,2.32c0,0.86,0.11,0.98,0.43,0.98c2.08,0,3.64,0,5.2,0c1.56,0,3.12,0,5.2,0
         C117.1,136.68,117.31,137.12,117.35,138.4z"
      />
      <path
        fill="#06b6d4"
        d="M136.08,138.58c1.21,0,1.32-0.43,1.36-1.65c0.04-1.28,0.04-3.73,0.04-5.5
         c0-0.86-0.11-0.98-0.43-0.98c-0.5,0-3.38-0.02-6.21-0.04c-2.83-0.02-5.6-0.06-5.88-0.08c-0.64-0.06-0.93-0.61-0.97-1.83
         c-0.03-1.13-0.05-2.87-0.06-4.69c-0.01-1.82-0.01-3.71-0.01-5.15c0-5.07,0.24-8.1,0.98-9.94c0.74-1.84,1.99-2.5,3.99-2.83
         c1.47-0.25,5.36-0.49,7.19-0.49c1.82,0,5.54,0.25,7.01,0.49c2,0.34,3.29,1.07,4.08,2.95c0.79,1.88,1.07,4.9,1.07,9.82
         c0,1.53-0.14,1.77-0.89,1.9c-0.75,0.12-3.15,0.12-4.36,0.12c-1.22,0-3.88,0.01-4.68-0.12c-0.67-0.11-0.72-0.18-0.75-1.22
         c-0.03-0.76-0.04-1.14-0.07-1.89c-0.04-1.35-0.32-1.59-1.39-1.59c-1.25,0-1.32,0.43-1.36,1.65c-0.04,1.28-0.04,3.73-0.04,4.89
         c0,0.86,0.11,0.98,0.43,0.98c0.5,0,3.38,0.02,6.21,0.04c2.83,0.02,5.6,0.06,5.88,0.08c0.64,0.06,0.93,0.61,0.96,1.83
         c0.03,1.13,0.05,3.03,0.06,5c0.01,1.97,0.01,4.02,0.01,5.46c0,5.07-0.22,8.1-0.95,9.94c-0.72,1.84-1.95,2.5-3.95,2.83
         c-1.46,0.24-5.44,0.49-7.26,0.49c-1.79,0-5.55-0.24-7.01-0.49c-2-0.33-3.29-0.99-4.08-2.91c-0.79-1.92-1.07-5.1-1.07-10.47
         c0-1.53,0.14-1.77,0.89-1.9c0.75-0.12,3.15-0.12,4.36-0.12c1.22,0,3.88-0.01,4.68,0.12c0.67,0.11,0.72,0.18,0.75,1.22
         c0.03,1,0.04,1.5,0.07,2.51C134.72,138.33,135.01,138.58,136.08,138.58z"
      />
      <g>
        <path
          fill="#f59e0b"
          d="M26.8,75.67c0,0.98,0,2.79-0.04,3.77c-0.04,1.22-0.56,1.76-1.5,1.76c-1.16,0-3.83-0.1-5.37-0.1
         c0,2.39,0,3.58,0,5.96c0,2.91,0,5.71-0.01,8.28c-0.01,2.57-0.04,4.9-0.1,6.88c-0.04,1.22-0.41,1.66-1.2,1.76
         c-0.75,0.1-2.67,0.2-4.55,0.2c-1.88,0-3.8-0.1-4.55-0.2c-0.79-0.1-1.17-0.54-1.2-1.76c-0.06-1.98-0.09-4.31-0.1-6.88
         c-0.01-2.57-0.01-5.37-0.01-8.28c0-2.39,0-3.58,0-5.96c-1.54,0-4.21,0.1-5.37,0.1c-0.94,0-1.46-0.54-1.5-1.76
         c-0.03-0.98-0.04-2.79-0.04-3.77s0-2.79,0.04-3.77c0.04-1.22,0.57-1.66,1.32-1.76c0.53-0.07,2.34-0.12,4.55-0.15
         c2.2-0.03,4.8-0.04,6.89-0.04c2.07,0,4.66,0.02,6.87,0.06c2.21,0.04,4.04,0.08,4.56,0.13c0.74,0.08,1.28,0.54,1.32,1.76
         C26.8,72.88,26.8,74.69,26.8,75.67z"
        />
        <path
          fill="#FF8000"
          d="M57.47,70.09c2.74,0.4,3.64,0.94,4.21,4.25c0.2,1.17,0.4,2.92,0.55,5.09
         c0.15,2.17,0.24,4.76,0.24,7.62s-0.09,5.45-0.24,7.62c-0.15,2.17-0.35,3.92-0.55,5.09c-0.57,3.31-1.47,3.86-4.21,4.25
         c-1.99,0.29-5.9,0.49-8.2,0.49s-6.21-0.2-8.2-0.49c-2.74-0.4-3.64-0.94-4.21-4.25c-0.2-1.17-0.4-2.92-0.55-5.09
         c-0.15-2.17-0.24-4.76-0.24-7.62s0.09-5.45,0.24-7.62c0.15-2.17,0.35-3.92,0.55-5.09c0.57-3.31,1.47-3.86,4.21-4.25
         c1.99-0.29,5.9-0.49,8.2-0.49S55.48,69.8,57.47,70.09z M48.34,78.6c-0.37,0.1-0.57,0.25-0.6,0.78
         c-0.12,1.95-0.11,4.94-0.11,7.68c0,2.74,0,5.73,0.11,7.68c0.04,0.58,0.23,0.7,0.6,0.78c0.22,0.05,0.64,0.1,0.94,0.1
         c0.3,0,0.72-0.04,0.94-0.1c0.37-0.09,0.57-0.25,0.6-0.78c0.13-1.94,0.11-4.94,0.11-7.68c0-2.74,0.01-5.73-0.11-7.68
         c-0.04-0.58-0.23-0.7-0.6-0.78c-0.22-0.05-0.64-0.1-0.94-0.1C48.98,78.5,48.52,78.55,48.34,78.6z"
        />
        <path
          fill="#FF8000"
          d="M102.39,70.09c0.71-0.09,2.26-0.15,4.02-0.15c1.73,0,3.68,0.1,4.4,0.19
         c0.71,0.1,1.02,0.59,1.02,1.47c0,0.73-0.15,2.15-0.38,4.2c-0.28,2.66-0.53,4.98-0.76,7.14c-0.23,2.16-0.44,4.15-0.65,6.14
         c-0.21,1.99-0.42,3.98-0.65,6.14c-0.23,2.16-0.48,4.48-0.76,7.14c-0.11,1.17-0.64,1.72-1.39,1.76c-1.79,0.11-4.62,0.1-6.88,0.1
         c-2.26,0-3.51,0.01-5.3-0.1c-0.79-0.05-1.28-0.59-1.39-1.76c-0.21-2.55-0.38-4.67-0.54-6.66c-0.16-1.98-0.31-3.82-0.47-5.81
         c-0.16-1.98-0.33-4.11-0.54-6.66c-0.06,0-0.09,0-0.15,0c-0.21,2.55-0.38,4.67-0.54,6.66s-0.31,3.82-0.47,5.81
         c-0.16,1.98-0.33,4.11-0.54,6.66c-0.11,1.17-0.61,1.71-1.39,1.76c-1.79,0.1-3.04,0.1-5.3,0.1c-2.26,0-5.09,0.01-6.88-0.1
         c-0.75-0.04-1.28-0.59-1.39-1.76c-0.28-2.66-0.53-4.98-0.76-7.14c-0.23-2.16-0.44-4.15-0.65-6.14
         c-0.21-1.99-0.42-3.98-0.65-6.14c-0.23-2.16-0.48-4.48-0.76-7.14c-0.23-2.05-0.38-3.47-0.38-4.2c0-0.88,0.3-1.37,1.02-1.47
         c0.71-0.1,2.67-0.19,4.4-0.19c1.77,0,3.32,0.06,4.02,0.15c1.23,0.16,1.47,0.49,1.58,2.3c0.15,2.56,0.28,4.69,0.39,6.67
         c0.12,1.99,0.23,3.83,0.34,5.82c0.12,1.99,0.24,4.12,0.39,6.67c0.06,0,0.09,0,0.15,0c0.31-2.62,0.56-4.81,0.8-6.84
         c0.24-2.04,0.46-3.93,0.7-5.97c0.24-2.04,0.49-4.22,0.8-6.84c0.15-1.27,0.42-1.66,1.13-1.76c0.67-0.1,2.33-0.19,4.06-0.19
         c1.73,0,3.39,0.1,4.06,0.19c0.71,0.1,0.98,0.49,1.13,1.76c0.31,2.62,0.56,4.8,0.8,6.84c0.24,2.04,0.46,3.93,0.7,5.97
         c0.24,2.04,0.49,4.22,0.8,6.84c0.06,0,0.09,0,0.15,0c0.15-2.56,0.28-4.69,0.39-6.67c0.12-1.99,0.23-3.83,0.34-5.82
         c0.12-1.99,0.24-4.12,0.39-6.67C100.93,70.58,101.16,70.25,102.39,70.09z"
        />
        <path
          fill="#FF8000"
          d="M139.11,70.14c0.67-0.1,2.63-0.19,3.95-0.19c1.62,0,3.42,0.1,4.06,0.19
         c0.67,0.1,0.98,0.54,1.02,1.76c0.06,1.98,0.08,4.33,0.1,6.91c0.01,2.58,0.01,5.39,0.01,8.3c0,2.86,0,5.66-0.01,8.24
         c-0.01,2.58-0.04,4.94-0.1,6.92c-0.04,1.22-0.3,1.66-0.98,1.76c-0.67,0.1-2.52,0.19-4.1,0.19c-1.35,0-2.3-0.06-2.93-0.15
         c-1.82-0.26-2.22-0.54-3.08-2.39c-1-2.11-1.75-3.7-2.5-5.28c-0.75-1.58-1.5-3.17-2.5-5.28c0,2.22,0,3.88,0,5.55s0,3.33,0,5.55
         c0,1.22-0.27,1.66-0.98,1.76c-0.67,0.1-2.63,0.2-3.95,0.2c-1.62,0-3.42-0.1-4.06-0.2c-0.67-0.1-0.98-0.54-1.02-1.76
         c-0.06-1.98-0.08-4.31-0.1-6.88c-0.01-2.57-0.01-5.37-0.01-8.28c0-2.86,0-5.66,0.01-8.24c0.01-2.58,0.04-4.94,0.1-6.92
         c0.04-1.22,0.42-1.66,1.2-1.76c0.75-0.1,2.78-0.19,3.99-0.19c0.98,0,2.18,0.05,2.63,0.1c1.84,0.25,2.22,0.59,3.08,2.45
         c1.04,2.19,1.82,3.83,2.59,5.48c0.78,1.64,1.56,3.29,2.59,5.48c0-2.31,0-4.04,0-5.77c0-1.73,0-3.46,0-5.77
         C138.13,70.68,138.4,70.24,139.11,70.14z"
        />
      </g>
      <path
        fill="#06b6d4"
        d="M19.63,25.7c2.46,0.51,3.17,1.41,3.62,5.32c0.11,0.92,0.25,2.49,0.36,4.49
         c0.11,2,0.19,4.43,0.19,7.06c0,1.16-0.07,1.54-0.58,1.59c-1.46,0.14-3.39,0.12-4.33,0.12c-0.68,0-3.5-0.02-4.27-0.12
         c-0.5-0.07-0.58-0.24-0.61-0.98c-0.03-0.73-0.04-4.9-0.1-5.87c-0.04-0.61-0.14-0.85-0.52-0.98c-0.16-0.05-0.55-0.12-0.81-0.12
         s-0.65,0.06-0.81,0.12c-0.32,0.12-0.49,0.31-0.52,0.98c-0.05,1.22-0.07,2.72-0.09,4.36c-0.01,1.64-0.01,3.43-0.01,5.23
         c0,1.62,0,3.41,0.01,5.1c0.01,1.69,0.03,3.28,0.09,4.5c0.03,0.67,0.19,0.86,0.52,0.98c0.16,0.06,0.55,0.12,0.81,0.12
         s0.65-0.07,0.81-0.12c0.37-0.13,0.48-0.37,0.52-0.98c0.06-0.97,0.1-5.81,0.13-6.54c0.03-0.73,0.11-0.91,0.61-0.98
         c0.76-0.1,3.55-0.12,4.23-0.12c0.94,0,2.87-0.02,4.33,0.12c0.51,0.05,0.62,0.43,0.61,1.59c-0.02,2.61-0.12,5.23-0.23,7.4
         c-0.11,2.17-0.24,3.91-0.35,4.83c-0.45,3.91-1.16,4.81-3.62,5.32c-1.7,0.35-5.07,0.61-7.04,0.61c-2.1,0-5.21-0.25-7.04-0.61
         c-2.48-0.49-3.13-1.34-3.62-5.32c-0.18-1.47-0.35-3.65-0.47-6.36c-0.13-2.71-0.21-5.95-0.21-9.53c0-3.58,0.08-6.82,0.21-9.53
         c0.13-2.71,0.3-4.9,0.47-6.36c0.49-4.14,1.27-4.82,3.62-5.32c1.71-0.36,5.07-0.61,7.04-0.61C14.55,25.09,17.93,25.35,19.63,25.7
         z"
      />
      <path
        fill="#06b6d4"
        d="M52.87,66.65c0,0.37-0.03,0.67-0.16,0.92c-0.16,0.3-0.42,0.48-0.91,0.55
         c-0.9,0.12-2.36,0.19-3.62,0.19c-1.26,0-2.66,0-3.65-0.19c-1.22-0.23-1.58-0.79-2-2.99c-0.37-1.99-0.66-3.49-0.94-4.98
         c-0.28-1.49-0.56-2.99-0.94-4.98c-0.45,0-0.68,0-1.13,0c0,2.13,0,3.72,0,5.32c0,1.6,0,3.19,0,5.32c0,1.53-0.32,2.08-1,2.2
         c-0.65,0.12-2.13,0.24-3.78,0.24c-1.58,0-3.01-0.12-3.65-0.24c-0.68-0.12-1-0.67-1.03-2.2c-0.03-1.65-0.05-3.5-0.07-5.5
         c-0.01-2-0.02-4.15-0.02-6.41c0-2.26,0-4.62,0-7.04c0-2.38,0-4.73,0-6.98c0-2.25,0.01-4.4,0.02-6.4c0.01-2,0.03-3.86,0.07-5.51
         c0.03-1.53,0.36-2.08,1.03-2.2c0.74-0.13,2.3-0.2,4.13-0.24c1.83-0.05,3.92-0.06,5.73-0.06c2.49,0,5.3,0.13,7.14,0.43
         c1.9,0.31,3.03,1.08,3.68,3.09c0.65,2.01,0.82,5.25,0.82,10.47c0,4.68-0.15,7.53-0.62,9.35c-0.46,1.82-1.24,2.6-2.48,3.12
         c0.61,2.46,1.06,4.3,1.52,6.14c0.46,1.84,0.91,3.69,1.52,6.14C52.84,65.55,52.87,66.16,52.87,66.65z M42.79,43.06
         c0.08-0.72,0.13-1.71,0.13-2.81c0-1.1-0.05-2.09-0.13-2.81c-0.11-0.96-0.19-1.22-0.68-1.22c-0.92,0-1.38,0-2.29,0
         c-0.23,0-0.32,0.37-0.32,1.22c0,2.25,0,3.37,0,5.62c0,0.92,0.1,1.22,0.32,1.22c0.92,0,1.38,0,2.29,0
         C42.6,44.28,42.68,44.02,42.79,43.06z"
      />
      <path
        fill="#06b6d4"
        d="M76.68,25.7c2.35,0.49,3.13,1.17,3.62,5.32c0.17,1.46,0.35,3.65,0.47,6.36
         c0.13,2.71,0.21,5.95,0.21,9.53c0,3.58-0.08,6.81-0.21,9.53c-0.13,2.71-0.3,4.9-0.47,6.36c-0.49,4.15-1.27,4.82-3.62,5.32
         c-1.71,0.36-5.07,0.61-7.05,0.61c-1.97,0-5.34-0.25-7.05-0.61c-2.35-0.49-3.13-1.17-3.62-5.32c-0.17-1.46-0.35-3.65-0.47-6.36
         c-0.13-2.71-0.21-5.95-0.21-9.53c0-3.58,0.08-6.82,0.21-9.53c0.13-2.71,0.3-4.9,0.47-6.36c0.49-4.15,1.27-4.82,3.62-5.32
         c1.71-0.36,5.07-0.61,7.05-0.61C71.61,25.09,74.97,25.34,76.68,25.7z M68.83,36.33c-0.32,0.12-0.49,0.31-0.52,0.98
         c-0.05,1.22-0.07,2.77-0.08,4.43c-0.01,1.67-0.01,3.45-0.01,5.17c0,1.71,0,3.5,0.01,5.16s0.03,3.21,0.08,4.43
         c0.03,0.73,0.2,0.87,0.52,0.98c0.19,0.07,0.55,0.12,0.81,0.12c0.26,0,0.62-0.06,0.81-0.12c0.32-0.11,0.49-0.31,0.52-0.98
         c0.05-1.22,0.07-2.77,0.08-4.43c0.01-1.67,0.01-3.45,0.01-5.16c0-1.71,0-3.5-0.01-5.17c-0.01-1.67-0.03-3.21-0.08-4.43
         c-0.03-0.73-0.2-0.87-0.52-0.98c-0.19-0.06-0.55-0.12-0.81-0.12C69.38,36.21,68.99,36.27,68.83,36.33z"
      />
      <path
        fill="#06b6d4"
        d="M112.06,25.7c0.61-0.11,1.94-0.18,3.46-0.18c1.49,0,3.17,0.12,3.78,0.24
         c0.62,0.12,0.87,0.73,0.87,1.83c0,0.92-0.13,2.69-0.32,5.26c-0.24,3.32-0.45,6.22-0.65,8.92c-0.2,2.7-0.38,5.19-0.56,7.68
         c-0.18,2.49-0.36,4.98-0.56,7.68c-0.2,2.7-0.41,5.6-0.65,8.92c-0.1,1.47-0.55,2.14-1.2,2.2c-1.54,0.13-3.98,0.12-5.91,0.12
         c-1.94,0-3.01,0.01-4.56-0.12c-0.68-0.06-1.1-0.73-1.2-2.2c-0.18-3.19-0.32-5.84-0.46-8.32c-0.14-2.48-0.27-4.78-0.4-7.26
         c-0.14-2.48-0.28-5.13-0.46-8.32c-0.05,0-0.08,0-0.13,0c-0.18,3.19-0.32,5.84-0.46,8.32c-0.14,2.48-0.27,4.78-0.4,7.26
         c-0.14,2.48-0.28,5.13-0.46,8.32c-0.1,1.47-0.52,2.14-1.2,2.2c-1.54,0.13-2.62,0.12-4.56,0.12c-1.94,0-4.37,0.01-5.91-0.12
         c-0.64-0.06-1.1-0.73-1.2-2.2c-0.24-3.32-0.45-6.22-0.65-8.92c-0.2-2.7-0.38-5.19-0.56-7.68c-0.18-2.49-0.36-4.98-0.56-7.68
         c-0.2-2.7-0.41-5.6-0.65-8.92c-0.19-2.57-0.32-4.34-0.32-5.26c0-1.1,0.26-1.71,0.87-1.83c0.61-0.12,2.29-0.24,3.78-0.24
         c1.52,0,2.85,0.07,3.46,0.18c1.05,0.2,1.26,0.61,1.36,2.87c0.13,3.19,0.24,5.86,0.34,8.34c0.1,2.48,0.19,4.79,0.29,7.28
         c0.1,2.48,0.21,5.15,0.34,8.34c0.05,0,0.08,0,0.13,0c0.26-3.28,0.48-6.01,0.69-8.55c0.2-2.55,0.39-4.91,0.6-7.46
         c0.2-2.55,0.42-5.28,0.69-8.55c0.13-1.59,0.36-2.07,0.97-2.2c0.58-0.12,2-0.24,3.49-0.24c1.49,0,2.91,0.12,3.49,0.24
         c0.61,0.13,0.84,0.61,0.97,2.2c0.26,3.28,0.48,6.01,0.69,8.55c0.2,2.55,0.39,4.91,0.6,7.46c0.2,2.55,0.42,5.28,0.69,8.55
         c0.05,0,0.08,0,0.13,0c0.13-3.19,0.24-5.86,0.34-8.34c0.1-2.48,0.19-4.79,0.29-7.28c0.1-2.48,0.21-5.15,0.34-8.34
         C110.8,26.31,111.01,25.89,112.06,25.7z"
      />
      <path
        fill="#06b6d4"
        d="M140.39,25.76c0.58-0.12,2.26-0.24,3.39-0.24c1.39,0,2.94,0.13,3.49,0.24
         c0.58,0.13,0.84,0.67,0.87,2.2c0.03,1.65,0.05,3.5,0.07,5.51c0.01,2.01,0.02,4.17,0.03,6.44s0,4.64,0,7.06c0,2.38,0,4.73,0,6.99
         c0,2.26-0.01,4.42-0.03,6.43c-0.01,2.01-0.03,3.88-0.07,5.52c-0.03,1.53-0.26,2.08-0.84,2.2c-0.58,0.12-2.16,0.25-3.52,0.25
         c-1.16,0-1.98-0.07-2.52-0.18c-1.56-0.32-1.91-0.67-2.65-3c-0.86-2.64-1.5-4.62-2.15-6.6c-0.64-1.98-1.29-3.96-2.15-6.6
         c0,2.78,0,4.86,0,6.94c0,2.08,0,4.16,0,6.94c0,1.53-0.23,2.08-0.84,2.2c-0.58,0.12-2.26,0.25-3.39,0.25
         c-1.39,0-2.94-0.13-3.49-0.25c-0.58-0.13-0.84-0.67-0.87-2.2c-0.03-1.65-0.05-3.5-0.07-5.5c-0.01-2-0.02-4.15-0.03-6.41
         c0-2.26,0-4.62,0-7.04c0-2.38,0-4.73,0-6.99c0-2.26,0.01-4.42,0.03-6.43c0.01-2.01,0.03-3.88,0.07-5.52
         c0.03-1.52,0.36-2.07,1.03-2.2c0.64-0.12,2.39-0.24,3.43-0.24c0.84,0,1.87,0.06,2.26,0.12c1.58,0.31,1.91,0.73,2.65,3.06
         c0.89,2.74,1.56,4.79,2.23,6.85c0.67,2.05,1.34,4.11,2.23,6.85c0-2.89,0-5.05,0-7.21s0-4.33,0-7.21
         C139.55,26.43,139.78,25.89,140.39,25.76z"
      />
      <path
        fill="#ec4899"
        d="M144.54,2.5c2.46,0,4.46,1.99,4.46,4.45v10.4v22.27c-5.18,0-9.74-10.08-12.4-14.1
   c-1.16-1.76-3.75-1.76-4.91,0c-2.66,4.02-7.22,6.67-12.4,6.67s-9.74-2.65-12.4-6.67c-1.16-1.76-3.75-1.76-4.91,0
   c-2.66,4.02-7.22,6.67-12.4,6.67c-5.12,0-9.63-2.59-12.3-6.53c-1.18-1.74-3.79-1.94-4.97-0.19c-2.71,3.99-0.5,14.14-12.43,14.14
   c-13.25,0-9.8-9.9-12.33-13.99c-1.15-1.85-3.84-1.78-5.06,0.03c-2.67,3.95-7.19,6.54-12.31,6.54c-5.18,0-9.74-2.65-12.4-6.67
   c-1.16-1.76-3.75-1.76-4.91,0c-2.66,4.02-7.22,14.1-12.4,14.1V17.35V6.95c0-2.46,1.99-4.45,4.45-4.45H144.54z"
      />
    </svg>
  );
};
